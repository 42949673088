import { render, staticRenderFns } from "./CasesFooterMain.vue?vue&type=template&id=0b072de0&scoped=true&"
import script from "./CasesFooterMain.vue?vue&type=script&setup=true&lang=js&"
export * from "./CasesFooterMain.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CasesFooterMain.vue?vue&type=style&index=0&id=0b072de0&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b072de0",
  null
  
)

/* custom blocks */
import block0 from "./CasesFooterMain.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports