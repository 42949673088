import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { useLiqpayStore } from '~/stores/liqpay';
var emptyCart = {
  cartState: 'cart',
  id: null,
  company: null,
  expire: null,
  items: [],
  total: {
    amount: 0,
    string: 'Жодного товару'
  }
};
export var useCartStore = defineStore({
  id: 'cart',
  state: function state() {
    return {
      carts: [],
      forceRefresh: false,
      activeCartIndex: 0
    };
  },
  getters: {
    isEmpty: function isEmpty() {
      return !this.itemsLength;
    },
    activeCart: function activeCart(state) {
      return state.carts[state.activeCartIndex] || emptyCart;
    },
    itemsLength: function itemsLength(state) {
      var number = 0;
      state.carts.forEach(function (cart) {
        return number += cart.items ? cart.items.length : 0;
      });
      return number;
    },
    nonEmptyCartsLength: function nonEmptyCartsLength(state) {
      var number = 0;
      var _iterator = _createForOfIteratorHelper(state.carts),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _cart$items;
          var cart = _step.value;
          if (((_cart$items = cart.items) === null || _cart$items === void 0 ? void 0 : _cart$items.length) > 0) number++;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return number;
    },
    nonEmptyCartIndex: function nonEmptyCartIndex(state) {
      return state.carts.findIndex(function (cart) {
        var _cart$items2;
        return ((_cart$items2 = cart.items) === null || _cart$items2 === void 0 ? void 0 : _cart$items2.length) > 0;
      });
    }
  },
  actions: {
    setActiveCartIndex: function setActiveCartIndex() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      if (!this.carts[index]) index = 0;
      this.activeCartIndex = index;
    },
    setCartState: function setCartState(cartState) {
      this.carts[this.activeCartIndex].cartState = cartState;
    },
    updateCart: function updateCart(payload) {
      payload.cartState = 'cart';
      if (payload.expireAt) payload.expireAt = new Date(payload.expireAt);
      var index = -1;
      if (payload.company) {
        index = this.carts.findIndex(function (cart) {
          return cart.company && cart.company.id == payload.company.id;
        });
      } else {
        index = this.carts.findIndex(function (cart) {
          return !cart.company;
        });
      }
      payload.cartState = 'cart';
      if (index >= 0 && this.carts[index].cartState == 'bill-editor') {
        payload.cartState = 'bill-editor';
      }
      if (index < 0) {
        return this.carts.push(payload);
      }
      this.carts.splice(index, 1, payload);
    },
    updateCarts: function updateCarts(payload) {
      var _this = this;
      this.carts = [];
      payload.forEach(function (cart) {
        if (cart.expireAt) cart.expireAt = new Date(cart.expireAt);
        cart.cartState = 'cart';
        _this.carts.push(cart);
      });
    },
    clear: function clear() {
      this.activeCart.items = [];
      this.activeCart.expire = null;
      this.activeCart.total = {
        amount: 0,
        string: 'Жодного товару'
      };
    },
    subscribe: function subscribe(_ref) {
      var slug = _ref.slug,
        price = _ref.price;
      var liqpayStore = useLiqpayStore();
      liqpayStore.setSubscription({
        liqpay: 'subscription',
        slug: slug,
        price: price
      });
      this.$nuxt.$modalityStore.openModal({
        type: 'cart',
        name: 'subscription'
      });
    }
  }
});