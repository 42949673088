<template>
    <picture>
        <source
            v-if="hasWebp && enableSources"
            :srcset="webpSet"
            type="image/webp"
        >

        <img
            :src="src"
            :srcset="srcSet"
            :alt="alt"
            :height="height"
            :width="width"
            :loading="loading"
            :class="imgClass"
            :id="imgId"
            :decoding="decoding"

            @error="errorHandler"
        >
    </picture>
</template>


<script>
    const allowedTypes = [`image/png`, `image/jpeg`];

    export default {
        props: ['src', 'srcset', `webpset`, 'alt', 'width', 'height', 'loading', 'imgClass', 'imgId', 'decoding'],

        data () {
            return {
                enableSources: true
            }
        },

        computed: {
            hasWebp () {
                return  allowedTypes.includes(this.type) && this.isCDN;
            },

            ext () {
                if (!this.src) return null;
                let arr = this.src.split('.');
                let ext = arr[arr.length - 1];
                return ext.toLowerCase();
            },

            type () {
                let type = this.ext == 'jpg' ? 'jpeg' : this.ext;
                return `image/${type}`;
            },

            isCDN () {
                const regex = /^https:\/\/cdn\.(?:[a-z0-9]+\.)?cases\.media\//;

                return regex.test(this.src);
            },

            srcSet () {
                return this.srcset || this.src;
            },

            webpSet () {
                return this.webpset || `${this.src}.webp`;
            },
        },

        methods: {
            errorHandler (e) {
                this.enableSources = false;
            }
        }
    }
</script>
