<template>
    <button
        @click="clickHandler"
        :class="{
            active: !exactActiveOnly ? isActive : false,
            'exact-active': isExactActive
        }"
        :data-closable="buttonId"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'DdButton',

    props: ['name', 'anchor', 'exactActiveOnly'],

    data () {
        return {
            id: null
        }
    },

    mounted () {
        this.id = this.$el && this.$el.id;
    },

    computed: {
        dd () {
            return this.$modalityStore.dropdowns.find(o => o.name == this.name);
        },

        isActive () {
            return !!this.dd;
        },

        isExactActive () {
            return  this.isActive && this.dd.anchorId == this.id;
        },

        buttonId () {
            return `${this.name}-dd`
        }
    },

    watch: {
        isExactActive (val) {
            if (val) this.observe();
            else this.unobserve();
        }
    },

    methods: {
        clickHandler (e) {
            let payload = {
                name: this.name,
                anchorId: this.anchor || this.$el.id,
            };

            this.$modalityStore.toggleDropdown(payload);
        },

        observe () {
            const scrollTopThreshold = 0.3;

            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    const visible = entry.isIntersecting && entry.intersectionRatio >= scrollTopThreshold;
                    if (!visible) this.$modalityStore.closeDropdown({name: this.name});
                })
            }, {
                root:       null,
                rootMargin: "0px 0px 0px 0px",
                threshold:  scrollTopThreshold
            });

            if (this.$el) this.observer.observe(this.$el);
        },

        unobserve: function () {
            if (this.observer) this.observer.disconnect();
        }
    },

    beforeDestroy () {
        this.unobserve();

        if (this.isExactActive) {
            this.$modalityStore.closeDropdown({name: this.name});
        }
    }
}
</script>

